<template>
  <div class="dump-page animated fadeIn container">
    <h1 class="mb-4">결과 추출</h1>
    <div style="overflow:hidden" v-if="this.$store.state.isAdmin">
      <select class="float-left form-control" placeholder="회원번호" v-model="selectType" style="width:150px">
        <option>회원번호</option>
        <option>추천코드</option>
      </select>
      <input type="text" class="float-left form-control ml-2" v-show="selectType == '회원번호'" placeholder="회원번호"
        v-model="userId" style="width:150px" />
      <input type="text" class="float-left form-control ml-2" v-show="selectType == '추천코드'" placeholder="추천코드"
        v-model="recommendCode" style="width:150px" />
    </div>
    <div class="mt-2" style="display:flex;align-items:center">
      <div class="tui-datepicker-input tui-datetime-input tui-has-focus">
        <input type="text" placeholder="시작시각" v-model="startDateText" @click="clickStartDateInput" readonly />
        <span class="tui-ico-date"></span>
      </div>
      <div v-show="showStartDatePicker" id="start-date-picker-container" style="margin-top: -1px;z-index:999"></div>
      <div class="tui-datepicker-input tui-datetime-input tui-has-focus" style="margin-left: 10px;">
        <input type="text" placeholder="종료시각" v-model="endDateText" @click="clickEndDateInput" readonly />
        <span class="tui-ico-date"></span>
      </div>
      <div v-show="showEndDatePicker" id="end-date-picker-container" style="margin-top: -1px;z-index:999"></div>
      <div class="ml-4 custom-control custom-checkbox custom-control-inline">
        <input type="checkbox" class="custom-control-input" id="successCheck" v-model="hasSuccess" />
        <label class="custom-control-label" for="successCheck">성공</label>
      </div>
      <div class="ml-2 custom-control custom-checkbox custom-control-inline">
        <input type="checkbox" class="custom-control-input" id="failCheck" v-model="hasFail" />
        <label class="custom-control-label" for="failCheck">실패</label>
      </div>
      <b-btn variant="primary" class="ml-2" @click.prevent="create">결과 추출</b-btn>
    </div>
    <table class="border-table mt-4">
      <thead>
        <tr>
          <td width="200px">요청일시</td>
          <td width="500px">요청 정보</td>
          <td width="160px">시작시각</td>
          <td width="160px">종료시각</td>
          <td>상태</td>
        </tr>
      </thead>
      <tbody>
        <tr v-if="!isLoading && items.length == 0">
          <td colspan="5" style="text-align:center">요청 정보가 없습니다</td>
        </tr>
        <tr :key="item.id" v-for="item in items">
          <td>
            {{ item.createdAt | dateFormat }}
          </td>
          <td style="max-width:500px;">
            {{ parseInfo(item) }}
          </td>
          <td>
            {{ item.startedAt | dateFormat2 }}
          </td>
          <td>
            {{ item.endedAt | dateFormat2 }}
          </td>
          <td class="text-center">
            <span v-show="item.status != '완료'" style="color: #63c2de">
              {{ item.status }}
            </span>
            <span v-show="item.status == '완료' && !isRecent(item.createdAt)" style="color: #9a9a9a">만료됨</span>
            <button v-show="item.status == '완료' && isRecent(item.createdAt)" class="btn btn-info"
              v-on:click="getFile(item.filePath)" style="color: white">
              다운로드
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <b-pagination align="center" :total-rows="totalCount" v-model="currentPage" :per-page="20" :limit="20" class="mt-4"
      @change="changePage" v-show="totalCount > limit"></b-pagination>
    <div class="download-dim" v-show="isDownloading">
      <pulse-loader :color="loaderStyle.color" :size="loaderStyle.size"></pulse-loader>
    </div>
  </div>
</template>

<script>
import DumpService from '@/services/DumpService'
import { Datetime } from 'vue-datetime'
import moment from 'moment'
import axios from 'axios'
import { mapGetters } from 'vuex'
import { Settings } from 'luxon'
import { PulseLoader } from 'vue-spinner/dist/vue-spinner.min.js'

Settings.defaultLocale = 'kr'

export default {
  name: 'Dumps',
  components: {
    Datetime,
    PulseLoader
  },
  data() {
    return {
      isLoading: true,
      isDownloading: false,
      items: [],
      userId: '',
      recommendCode: '',
      startDate: new Date(),
      endDate: new Date(),
      startDateText: '',
      endDateText: '',
      tempStartDate: null,
      tempEndDate: null,
      showStartDatePicker: false,
      showEndDatePicker: false,
      hasMessage: true,
      hasSuccess: true,
      hasFail: true,
      limit: 20,
      currentPage: 1,
      totalCount: 0,
      selectType: '회원번호',
      loaderStyle: {
        color: '#fff',
        size: '10px',
      },
    }
  },
  mounted() {
    document.body.classList.add('bg-white')
    this.getList()
    this.initStartDatePicker()
    this.initEndDatePicker()
  },
  methods: {
    initStartDatePicker() {
      const t = this;
      if (!window.tui || !window.tui.DatePicker || !window.tui.TimePicker) {
        setTimeout(function () {
          t.initStartDatePicker()
        }, 500)
        return
      }

      const minDate = moment()
        .add(-6, 'M')
        .toISOString();
      const maxDate = moment()
        .endOf('day')
        .toISOString();

      t.tempStartDate = this.startDate

      const datePicker = new window.tui.DatePicker('#start-date-picker-container', {
        selectableRanges: [
          [minDate, maxDate],
        ],
        date: this.startDate,
        timePicker: {
          language: 'ko',
          meridiemPosition: 'left',
          date: this.startDate,
        },
        showAlways: true,
        language: 'ko'
      });

      const pickerBtnWrap = document.createElement('div');
      pickerBtnWrap.classList.add('tui-datepicker-footer');
      pickerBtnWrap.classList.add('btn-wrap');
      pickerBtnWrap.innerHTML = '<button class="btn-primary btn-ok">확인</button><button class="btn-secondary btn-cancel ml-10">취소</button>';
      pickerBtnWrap.querySelector('.btn-ok').addEventListener('click', () => {
        t.showStartDatePicker = false

        t.startDate = t.tempStartDate

        const m = moment(t.tempStartDate)
        t.startDateText = m.format('YYYY-MM-DD') + (m.hour() < 12 ? ' 오전 ' : ' 오후 ') + m.format('hh:mm');
      })

      pickerBtnWrap.querySelector('.btn-cancel').addEventListener('click', () => {
        t.showStartDatePicker = false
      })

      document.querySelector('#start-date-picker-container .tui-datepicker-footer').after(pickerBtnWrap);

      datePicker.on('change', () => {
        t.tempStartDate = datePicker.getDate()
      })
    },
    initEndDatePicker() {
      const t = this;
      if (!window.tui || !window.tui.DatePicker || !window.tui.TimePicker) {
        setTimeout(function () {
          t.initEndDatePicker()
        }, 500)
        return
      }

      const minDate = moment()
        .add(-6, 'M')
        .toISOString();
      const maxDate = moment()
        .endOf('day')
        .toISOString();

      t.tempEndDate = this.endDate

      const datePicker = new window.tui.DatePicker('#end-date-picker-container', {
        selectableRanges: [
          [minDate, maxDate],
        ],
        date: this.endDate,
        timePicker: {
          language: 'ko',
          meridiemPosition: 'left',
          date: this.endDate,
        },
        showAlways: true,
        language: 'ko'
      });

      const pickerBtnWrap = document.createElement('div');
      pickerBtnWrap.classList.add('tui-datepicker-footer');
      pickerBtnWrap.classList.add('btn-wrap');
      pickerBtnWrap.innerHTML = '<button class="btn-primary btn-ok">확인</button><button class="btn-secondary btn-cancel ml-10">취소</button>';
      pickerBtnWrap.querySelector('.btn-ok').addEventListener('click', () => {
        t.showEndDatePicker = false

        t.endDate = t.tempEndDate

        const m = moment(t.tempEndDate)
        t.endDateText = m.format('YYYY-MM-DD') + (m.hour() < 12 ? ' 오전 ' : ' 오후 ') + m.format('hh:mm');
      })

      pickerBtnWrap.querySelector('.btn-cancel').addEventListener('click', () => {
        t.showEndDatePicker = false
      })

      document.querySelector('#end-date-picker-container .tui-datepicker-footer').after(pickerBtnWrap);

      datePicker.on('change', () => {
        t.tempEndDate = datePicker.getDate()
      })
    },
    clickStartDateInput() {
      this.showStartDatePicker = true
    },
    clickEndDateInput() {
      this.showEndDatePicker = true
    },
    async getFile(filePath) {
      this.isDownloading = true
      axios({
        url: '/api/file?fileKey=' + filePath,
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {

        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filePath.split('/').pop())
        document.body.appendChild(link)
        link.click()
      }).finally(() => {
        this.isDownloading = false
      })
    },
    async getList() {
      this.isLoading = true
      try {
        const response = await DumpService.list({
          page: this.currentPage,
        })

        this.totalCount = response.data.totalCount
        if (response.status == 200) {
          this.items = response.data.list
        }
      } catch (e) {
        // Do nothing
      } finally {
        this.isLoading = false
      }
    },
    async create() {
      var isAdmin = this.user.level == 9
      if (isAdmin) {
        if (this.selectType == '회원번호' && this.userId.length == 0) {
          alert('회원번호를 입력해주세요')

          return
        } else if (this.selectType == '추천코드' && this.recommendCode.length == 0) {
          alert('추천코드를 입력해주세요')
          return
        }
      }

      if (this.startDate.length == 0) {
        alert('시작일을 입력해주세요')
        return
      }
      if (this.endDate.length == 0) {
        alert('종료일을 입력해주세요')
        return
      }

      let params = {
        startDate: this.startDate,
        endDate: this.endDate,
        hasMessage: this.hasMessage,
        hasSuccess: this.hasSuccess,
        hasFail: this.hasFail,
      }

      if (this.selectType == '회원번호') {
        params.userId = this.userId
      } else {
        params.recommendCode = this.recommendCode
      }
      const response = await DumpService.create(params)

      if (response.status == 200) {
        alert('요청 성공')
        this.userId = ''
        this.startDate = ''
        this.endDate = ''
        this.hasMessage = true
        this.hasSuccess = true
        this.hasFail = true
        this.getList()
      }
    },
    isRecent(createdAt) {
      if (!createdAt || createdAt.length == 0) {
        return
      }

      var date = moment().add(-10, 'days')
      return date.isBefore(moment(String(createdAt)))
    },
    parseInfo(item) {
      var text = ''
      var isAdmin = this.user.level == 9
      if (isAdmin) {
        if (item.userId != null) {
          text += '회원번호: ' + item.userId
        }

        if (item.recommendCode) {
          text += '추천코드: ' + item.recommendCode
        }
      }

      if (text.length > 0) {
        text += ' / '
      }

      var statusList = []
      // if (item.hasMessage == 1) {
      //   statusList.push('메시지 포함')
      // }

      if (item.hasSuccess) {
        statusList.push('성공')
      }

      if (item.hasFail) {
        statusList.push('실패')
      }
      text += statusList.join(', ')

      return text
    },
    changePage(page) {
      var query = {
        page: page,
      }

      this.$router.push({
        path: '/dumps',
        query,
      })
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
  watch: {
    '$route.query.page'() {
      this.currentPage = this.$route.query.page
      this.getList()
    },
  },
}
</script>
<style scoped>
.single-line {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}

.download-dim {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
</style>
