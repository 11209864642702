import Api from "@/services/Api";

export default {
  create(params) {
    return Api().post("/api/dumpRequests", params);
  },

  list(params) {
    return Api().get("/api/dumpRequests", { params });
  }
};
